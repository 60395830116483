import * as yup from "yup";
import { ConfigurationDateFormat, ConfigurationTimeFormat } from "../constants";

export const UpdateUserConfigurationFormValidationSchema = yup
  .object()
  .shape({
    dateFormat: yup
      .string()
      .required()
      .oneOf(Object.values(ConfigurationDateFormat), "Invalid date format"),
    timeFormat: yup
      .string()
      .required()
      .oneOf(Object.values(ConfigurationTimeFormat), "Invalid time format"),
  })
  .required();
