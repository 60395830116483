import { useContext, useEffect } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { UserInfo } from "@microsoft/teamsfx";
import { useQuery } from "@tanstack/react-query";
import { User, UserApi } from "../../../api/UserApi";
import { TeamsFxContext } from "../../../context/TeamsFxContext";
import { useStoreContext } from "../../../context/StoreContext";
import { QueryKey } from "../../../constants/api";
import {
  ConfigurationDateFormat,
  ConfigurationTimeFormat,
} from "../../Settings/components/Config/constants";

interface UserProfileHook {
  // Teams user
  isLoadingTeamsUser: boolean;
  teamsUser: UserInfo | undefined;

  // User
  isLoadingUser: boolean;
  isErrorUser: boolean;
  isSuccessUser: boolean;
  user: User | null | undefined;
  refetchUser: () => void;
}

export const useUserProfile = (): UserProfileHook => {
  // Get authenticated user data (from Teams)
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading: isLoadingTeamsUser, data: teamsUser } = useData(async () => {
    if (teamsUserCredential) {
      return await teamsUserCredential.getUserInfo();
    }
  });
  const { setUserSettings } = useStoreContext();

  // Get user details from the API
  const {
    isLoading: isLoadingUser,
    isError: isErrorUser,
    isSuccess: isSuccessUser,
    data: user,
    refetch: refetchUser,
  } = useQuery({
    queryKey: [QueryKey.GetUser, teamsUser?.objectId],
    queryFn: () => UserApi.getUser(teamsUser?.objectId),
    enabled: !!teamsUser?.objectId,
  });

  useEffect(() => {
    if (isSuccessUser && user?.configurations?.settings) {
      const { settings } = user.configurations;

      setUserSettings({
        dateFormat: settings?.dateFormat ?? ConfigurationDateFormat.Default,
        timeFormat: settings?.timeFormat ?? ConfigurationTimeFormat.Default,
      });
    }
  }, [isSuccessUser, user, setUserSettings]);

  return {
    // Teams user
    isLoadingTeamsUser,
    teamsUser,

    // User
    isLoadingUser,
    isErrorUser,
    isSuccessUser,
    user,
    refetchUser,
  };
};
