import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { TeamsFxContext } from "../../context/TeamsFxContext";
import { containerBreakpoints } from "../../utils/breakpoints";

export interface LayoutProps extends PropsWithChildren<{}> {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),

    ...containerBreakpoints.xs({
      ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    }),
  },
});

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const styles = useStyles();

  const { teamsUserCredential } = useContext(TeamsFxContext);

  // Note: This is not the best place to store the token. but this component is a wrapper for the whole app.

  const syncAuthToken = useCallback(
    async (teamsUserCredential: TeamsUserCredential) => {
      const { ssoToken } = teamsUserCredential as any;

      if (ssoToken?.token) {
        localStorage.setItem("authToken", ssoToken.token);
      } else {
        // TODO: This is a temporary dirty fix for the issue that the token is not ready when the app is loaded.
        setTimeout(() => {
          syncAuthToken(teamsUserCredential);
        }, 300);
      }
    },
    []
  );

  useEffect(() => {
    if (teamsUserCredential) {
      syncAuthToken(teamsUserCredential);
    }
  }, [teamsUserCredential, syncAuthToken]);

  useEffect(() => {
    refreshToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshToken = async () => {
    console.log("Refreshing token");

    teamsUserCredential?.getToken("").then((token) => {
      if (token && token.expiresOnTimestamp) {
        console.log("Token expires on: %s", new Date(token.expiresOnTimestamp));
        console.log("Current time: %s", new Date());

        if (token.expiresOnTimestamp > Date.now()) {
          console.log("Token is still valid");
        } else {
          console.log("Token is expired");

          // Refresh page to get a new token
          window.location.reload();
        }
      } else {
        console.log("Token is not available");

        // Refresh page to get a new token
        window.location.reload();
      }

      setTimeout(() => {
        refreshToken();
      }, 60 * 1000);
    });
  };

  // User has switched back to the tab
  const onFocus = () => {
    refreshToken();
  };

  const onVisibilityChange = () => {
    if (!document.hidden) {
      refreshToken();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("visibilitychange", onVisibilityChange);

    // Calls onFocus when the window first loads
    onFocus();

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={styles.root}>{children}</div>;
};
