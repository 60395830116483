import React from "react";
import {
  Body1,
  Button,
  MessageBarActions,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import { useUserProfile } from "../../../DashBoard/hooks/useUserProfile";
import { UserRole } from "../../../../api/UserApi";
import { Alert } from "../../../../components/Alert";
import AchievementSkeleton from "../../../../components/Skeletons/AchievementSkeleton";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { UsersTable } from "./components/UsersTable";
import { AdminProvider } from "./context/AdminContext";
import { EditUserDialog } from "./components/EditUserDialog";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Admin = () => {
  const styles = useStyles();

  const { isLoadingUser, isSuccessUser, isErrorUser, user, refetchUser } =
    useUserProfile();

  return (
    <AdminProvider>
      <TabPanel title="Admin Panel">
        <div className={styles.root}>
          <Body1>
            Where great power comes with great responsibility. Admins can manage
            user permissions, configure advanced settings, and oversee the
            operation of PizzaTime to ensure smooth and optimal user engagement.
          </Body1>

          {isLoadingUser && (
            <>
              <AchievementSkeleton />
            </>
          )}

          {isErrorUser && (
            <Alert
              intent="error"
              actions={
                <MessageBarActions
                  containerAction={
                    <Button
                      appearance="transparent"
                      icon={<ArrowCounterclockwise24Regular />}
                      onClick={() => refetchUser()}
                    />
                  }
                />
              }
            />
          )}

          {isSuccessUser && user?.role === UserRole.User && (
            <Alert
              intent="info"
              title="Oh no!"
              message="You don't have permissions to access this section. Sorry :("
            />
          )}

          {isSuccessUser && user?.role === UserRole.TenantAdmin && (
            <>
              <UsersTable />
              <EditUserDialog />
            </>
          )}
        </div>
      </TabPanel>
    </AdminProvider>
  );
};
