import {
  Caption1,
  Rating,
  makeStyles,
  shorthands,
  tokens,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
  Tooltip,
} from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { DialogFeedback } from "./DialogFeedback";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FeedbackApi,
  UserFeedback,
  UserFeedbackStatus,
} from "../../../../api/FeedbackApi";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useStoreContext } from "../../../../context/StoreContext";
import {
  EmojiSadRegular,
  FoodPizzaFilled,
  FoodPizzaRegular,
  PersonStarFilled,
  RibbonStarFilled,
  SendRegular,
} from "@fluentui/react-icons";
import Button from "../../../../components/Button";
import { QueryKey } from "../../../../constants/api";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    containerType: "inline-size",
    containerName: "container",
    ...shorthands.gap(tokens.spacingVerticalS),
  },
  inlineForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingHorizontalS),

    "@container (max-width: 490.98px)": {
      ...shorthands.margin(tokens.spacingVerticalS, 0),

      "& .fui-Button": {
        minWidth: "auto",

        "&__icon": {
          marginRight: 0,
        },

        "& .inline-button": {
          backgroundColor: "red",
          display: "none",
        },
      },
    },
  },
});

interface InlineFeedbackProps {
  userFeedback: UserFeedback;
}

export const InlineFeedback: React.FC<InlineFeedbackProps> = ({
  userFeedback,
}) => {
  const styles = useStyles();

  const { teamsUser } = useUserProfile();
  const { dispatchToast } = useStoreContext();
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleLeaveFeedback = () => {
    setIsFeedbackDialogOpen(true);
  };

  const onFeedbackDialogClose = () => {
    setIsFeedbackDialogOpen(false);
  };

  const handleFeedbackMessageSubmit = () => {
    onFeedbackDialogClose();
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GetUser, teamsUser?.objectId],
    });

    dispatchToast(
      <Toast>
        <ToastTitle media={<PersonStarFilled />}>I'm thrilled!</ToastTitle>
        <ToastBody>
          I am incredibly grateful for the time you dedicated to provide me with
          your valuable feedback!
        </ToastBody>
        <ToastFooter>Thank you!!! 🍕</ToastFooter>
      </Toast>,
      {
        intent: "success",
        timeout: 5000,
      }
    );
  };

  const {
    isPending: sendUserFeedbackIsPending,
    mutateAsync: sendUserFeedback,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.UpdateUserFeedback],
    mutationFn: FeedbackApi.updateUserFeedback,
  });

  const handleSendPizzaFeedback = async () => {
    if (userFeedback?.endorsement) {
      return;
    }

    try {
      const response = await sendUserFeedback({
        ...userFeedback,
        endorsement: true,
        status: UserFeedbackStatus.PartiallyCollected,
      });

      if (response?.id) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetUser, teamsUser?.objectId],
        });

        dispatchToast(
          <Toast>
            <ToastTitle media={<FoodPizzaFilled />}>Delicious!</ToastTitle>
            <ToastBody>
              It's such an honour for me to receive your endorsement. I'm glad
              you enjoyed the experience!
            </ToastBody>
            <ToastFooter>Thank you very much!</ToastFooter>
          </Toast>,
          {
            intent: "success",
            timeout: 5000,
          }
        );
      }
    } catch (error) {
      // Note: This is an empty catch block. It is important to handle the error.
      // It will be handled in the useEffect below.
    }
  };

  const handleSendRatingFeedback = async (_: any, data: { value: number }) => {
    try {
      const response = await sendUserFeedback({
        ...userFeedback,
        score: data.value,
        status: UserFeedbackStatus.PartiallyCollected,
      });

      if (response?.id) {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.GetUser, teamsUser?.objectId],
        });

        // TODO: Add variety of thanks based on the score
        dispatchToast(
          <Toast>
            <ToastTitle media={<RibbonStarFilled />}>Amazing!</ToastTitle>
            <ToastBody>Your rating means a lot to me!</ToastBody>
            <ToastFooter>Thank you, mate!</ToastFooter>
          </Toast>,
          {
            intent: "success",
          }
        );
      }
    } catch (error) {
      // Note: This is an empty catch block. It is important to handle the error.
      // It will be handled in the useEffect below.
    }
  };

  const handleFeedbackError = useCallback(() => {
    dispatchToast(
      <Toast>
        <ToastTitle media={<EmojiSadRegular />}>Oops!</ToastTitle>
        <ToastBody>
          Something went wrong while trying to collect your feedback.
        </ToastBody>
        <ToastFooter>Please try again later.</ToastFooter>
      </Toast>,
      {
        intent: "error",
      }
    );
  }, [dispatchToast]);

  useEffect(() => {
    if (isError) {
      console.error("Error while collecting feedback", error);
      handleFeedbackError();
    }
  }, [isError, error, handleFeedbackError]);

  return (
    <div className={styles.root}>
      <Caption1>
        It's been a while since you're here, so you should be aware that this is
        is not just an app - it's a passion project and I spent countless hours
        crafting this experience. I hope it brings a smile to your face at the
        end of the day and I would love to hear your thoughts. I'm all ears!
      </Caption1>
      <div className={styles.inlineForm}>
        <Tooltip content="Share some feedback" relationship="label">
          <Button
            appearance="primary"
            icon={<SendRegular />}
            onClick={handleLeaveFeedback}
          >
            <span className="inline-button">Send me a message</span>
          </Button>
        </Tooltip>
        <Tooltip content="Send me something delicious" relationship="label">
          <Button
            isLoading={sendUserFeedbackIsPending}
            onClick={handleSendPizzaFeedback}
            icon={<FoodPizzaRegular />}
            disabled={userFeedback?.endorsement}
          >
            <span className="inline-button">
              {userFeedback?.endorsement ? "Delicious 🍕" : "Send me a pizza"}
            </span>
          </Button>
        </Tooltip>
        <Tooltip
          content="Rate your experience with PizzaTime"
          relationship="label"
        >
          <Rating
            value={userFeedback.score}
            max={5}
            color="marigold"
            onChange={handleSendRatingFeedback}
          />
        </Tooltip>
      </div>

      <DialogFeedback
        isOpen={isFeedbackDialogOpen}
        onClose={onFeedbackDialogClose}
        userFeedback={userFeedback}
        onSuccess={handleFeedbackMessageSubmit}
      />
    </div>
  );
};
