import React, { FC } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardPreview,
  MessageBarActions,
  Title1,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { Alert } from "../../../components/Alert";

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
} from "@fluentui/react-components";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { TableColumn } from "../../../components/Table";
import { useLeaderBoardContext } from "../context/LeaderBoardContext";

export interface LeaderBoardTableRow {
  key: number;
  rank: number;
  name: string;
  score: number;
  providerUserId: string;
}

export interface LeaderBoardTableProps {
  title: string;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  columns: TableColumn[];
  rows: LeaderBoardTableRow[];
  onRefresh: () => void;
}

const useStyles = makeStyles({
  cardHeader: {
    "& > div": {
      ...containerBreakpoints.xs({
        justifyContent: "center",
      }),
    },
  },
  cardPreview: {
    ...shorthands.padding(tokens.spacingHorizontalS),
  },
  table: {
    display: "table !important",
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

// TODO: Add ranges selection for specific periods
// TODO: Add unit tests and verify the table is working as expected + open user profile dialog
export const LeaderBoardTable: FC<LeaderBoardTableProps> = ({
  title,
  isLoading,
  isError,
  isSuccess,
  columns,
  rows,
  onRefresh,
}) => {
  const { setIsUserProfileDialogOpen, setSelectedUser } =
    useLeaderBoardContext();

  const styles = useStyles();

  const handleOpenUserProfile = (user: LeaderBoardTableRow) => {
    setIsUserProfileDialogOpen(true);
    setSelectedUser(user);
  };

  return (
    <Card>
      <CardHeader
        className={styles.cardHeader}
        header={<Title1>{title}</Title1>}
      ></CardHeader>
      <CardPreview className={styles.cardPreview}>
        {isLoading && <TableSkeleton />}

        {isError && (
          <Alert
            intent="error"
            actions={
              <MessageBarActions
                containerAction={
                  <Button
                    appearance="transparent"
                    icon={<ArrowCounterclockwise24Regular />}
                    onClick={onRefresh}
                    data-testid="retry-fetch-leaderboard-button"
                  />
                }
              />
            }
          />
        )}

        {isSuccess && rows.length === 0 && (
          <Alert
            intent="info"
            title="Get ready to climb the ranks!"
            message={`Our leaderboard will soon display the ${
              title === "Top Recipients"
                ? "most endorsed colleagues"
                : "top givers"
            }, fueling a friendly competition to spread joy and recognition across the team.`}
          />
        )}

        {isSuccess && rows.length > 0 && (
          <Table
            arial-label={title}
            size="small"
            className={styles.table}
            data-testid="leaderboard-table"
          >
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHeaderCell
                    key={column.columnKey}
                    className={styles.tableHeader}
                    style={{ width: column.width ?? "auto" }}
                  >
                    {column.label}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.key}
                  data-testid="leaderboard-table-row"
                  onClick={() => handleOpenUserProfile(row)}
                >
                  <TableCell>{row.rank}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardPreview>
    </Card>
  );
};

export default LeaderBoardTable;
