import React, { useContext, useState } from "react";
import {
  Divider,
  LargeTitle,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabSlots,
  TabValue,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { containerBreakpoints } from "../../utils/breakpoints";
import Setup from "../Setup";
import { useStoreContext } from "../../context/StoreContext";
import {
  ChatSettingsFilled,
  NotepadRegular,
  PersonFeedbackRegular,
  PersonSettingsFilled,
  SettingsCogMultipleFilled,
} from "@fluentui/react-icons";
import { Changelog } from "./components/Changelog";
import { Admin } from "./components/Admin";
import { TeamsContextType, TeamsFxContext } from "../../context/TeamsFxContext";
import { getTeamsContext } from "../../utils/helpers";
import { Chat } from "./components/Chat";
import { CHAT_TYPE_LABELS } from "./components/Chat/constants";
import { Config } from "./components/Config";
import { Feedback } from "./components/Feedback";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    containerType: "inline-size",
    containerName: "page-container",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
    }),
  },
  headerTitle: {
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
    }),
  },

  content: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    rowGap: "20px",

    ...containerBreakpoints.xs({
      flexDirection: "column",
    }),
  },
  panels: {
    flexGrow: 1,
    width: "100%",
    ...shorthands.padding(0, "10px"),
  },
});

export enum SettingsTab {
  Chat = "chat",
  Config = "config",
  Admin = "admin",
  Changelog = "changelog",
  Feedback = "feedback",
}

export const SETTINGS_TAB_CONFIG: Array<{
  key: SettingsTab;
  label: string;
  icon: TabSlots["icon"];
}> = [
  {
    key: SettingsTab.Config,
    label: "Config",
    icon: <SettingsCogMultipleFilled />,
  },
  {
    key: SettingsTab.Admin,
    label: "Admin",
    icon: <PersonSettingsFilled />,
  },
  {
    key: SettingsTab.Changelog,
    label: "Changelog",
    icon: <NotepadRegular />,
  },
  {
    key: SettingsTab.Feedback,
    label: "Feedback",
    icon: <PersonFeedbackRegular />,
  },
];

export const Settings = () => {
  const styles = useStyles();
  const { teams } = useContext(TeamsFxContext);

  const teamContext = getTeamsContext(teams);

  // TODO: The bug is resolved, cleanup the log after some time
  console.log("bug-1: teamContext", teamContext);

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    teamContext !== TeamsContextType.Personal ? "chat" : "config"
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const { isTenantConfigured } = useStoreContext();
  if (!isTenantConfigured) {
    return <Setup />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <LargeTitle block className={styles.headerTitle}>
          Settings
        </LargeTitle>
      </div>
      <Divider />
      <div className={styles.content}>
        <TabList
          selectedValue={selectedValue}
          onTabSelect={onTabSelect}
          vertical
        >
          {teamContext !== TeamsContextType.Personal && (
            <Tab
              id={SettingsTab.Chat}
              icon={<ChatSettingsFilled />}
              value={SettingsTab.Chat}
            >
              {CHAT_TYPE_LABELS[getTeamsContext(teams)]}
            </Tab>
          )}

          {SETTINGS_TAB_CONFIG.map((tabConfig) => (
            <Tab
              id={tabConfig.key}
              value={tabConfig.key}
              icon={tabConfig.icon}
              key={tabConfig.key}
            >
              {tabConfig.label}
            </Tab>
          ))}
        </TabList>

        <div className={styles.panels}>
          {selectedValue === SettingsTab.Chat && <Chat />}
          {selectedValue === SettingsTab.Config && <Config />}
          {selectedValue === SettingsTab.Admin && <Admin />}
          {selectedValue === SettingsTab.Changelog && <Changelog />}
          {selectedValue === SettingsTab.Feedback && <Feedback />}
        </div>
      </div>
    </div>
  );
};
