import { Api } from "./Api";

export type Team = {
  id: number;
  tenantId: string;
  name: string;
};

export class TeamApi {
  public static API_ENDPOINT = `teams`;

  public static async getTeams(
    providerTenantId: string | undefined
  ): Promise<Team[]> {
    if (!providerTenantId) {
      return [];
    }

    const url = `${TeamApi.API_ENDPOINT}/${providerTenantId}`;
    const response = await Api.get(url);

    return response;
  }
}
