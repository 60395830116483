import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import {
  Button,
  MessageBarActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useData } from "@microsoft/teamsfx-react";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { initializeIcons } from "@fluentui/react";

import TableSkeleton from "../../../../components/Skeletons/TableSkeleton";
import { EndorsementApi } from "../../../../api/EndorsementApi";
import { mapUsersActivityToTableRows } from "../../utils/helpers";
import { Alert } from "../../../../components/Alert";
import { TeamsFxContext } from "../../../../context/TeamsFxContext";
import { useDashboardContext } from "../../context/DashboardContext";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/global";
import { containerBreakpoints } from "../../../../utils/breakpoints";
import Pagination, {
  PaginationConfig,
} from "../../../../components/Pagination";
import { formatDateTime } from "../../../../utils/helpers";
import { useStoreContext } from "../../../../context/StoreContext";
import { QueryKey } from "../../../../constants/api";

initializeIcons();

export interface ActivityTableRow {
  key: number;
  icon: React.ReactElement;
  message: string;
  date: Date;
}

interface ActivityTableProps {}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  table: {
    display: "table !important",
  },
  tableColumnIcon: {
    width: "30px",
  },
  tableColumnDate: {
    width: "105px",
    fontSize: "10px",
    ...containerBreakpoints.xs({
      display: "none",
    }),
  },
});

export const ActivityTable: React.FC<ActivityTableProps> = () => {
  const styles = useStyles();
  const [rows, setRows] = useState<ActivityTableRow[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    page: 0,
    limit: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { activityFilterPeriod } = useDashboardContext();
  const { userSettings } = useStoreContext();

  const { data: userData } = useData(async () => {
    if (teamsUserCredential) {
      return await teamsUserCredential.getUserInfo();
    }
  });

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: [
      QueryKey.GetUserActivity,
      userData?.objectId,
      activityFilterPeriod,
      selectedPage,
    ],
    queryFn: () =>
      EndorsementApi.getUserActivity(userData?.objectId, {
        filters: {
          fromDate: activityFilterPeriod[0],
          toDate: activityFilterPeriod[1],
        },
        pagination: {
          page: selectedPage + 1,
          limit: pagination.limit,
        },
      }),
    enabled: !!userData?.objectId,
  });

  useEffect(() => {
    if (data) {
      setRows(mapUsersActivityToTableRows(data.data));
      setPagination(data.pagination);
    }
  }, [data]);

  const onPageChange = (index: number) => {
    setSelectedPage(index);
  };

  return (
    <div data-testid="dashboard-activity-table">
      {isLoading && <TableSkeleton />}

      {isError && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  onClick={() => refetch()}
                  data-testid="retry-fetch-activity-button"
                />
              }
            />
          }
        />
      )}

      {isSuccess && rows.length === 0 && (
        <Alert
          intent="info"
          title="Oh no! There is no activity for this period yet..."
          message="Stay tuned! Your activity feed will soon be brimming with endorsements from your colleagues. Every slice of appreciation you send and receive will be showcased here, fostering a culture of gratitude and recognition within your team."
        />
      )}

      {isSuccess && (
        <div className={styles.container}>
          <Table
            arial-label="Activity"
            size="small"
            className={styles.table}
            data-testid="activity-table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key} data-testid="activity-table-row">
                  <TableCell className={styles.tableColumnIcon}>
                    {row.icon}
                  </TableCell>
                  <TableCell>{row.message}</TableCell>
                  <TableCell className={styles.tableColumnDate}>
                    <Tooltip
                      withArrow
                      content={formatDateTime(
                        row.date,
                        userSettings.dateFormat,
                        userSettings.timeFormat
                      )}
                      relationship="label"
                    >
                      <span>{formatDistanceToNow(row.date)} ago</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {(pagination?.total && pagination.total > pagination.limit) ===
            true && (
            <Pagination
              selectedPage={selectedPage}
              pagination={pagination}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};
