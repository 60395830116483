import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  Button,
  MessageBarActions,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  makeStyles,
  shorthands,
  tokens,
  useArrowNavigationGroup,
  useFocusableGroup,
} from "@fluentui/react-components";
import { User } from "../../../../../api/UserApi";
import { useUserProfile } from "../../../../DashBoard/hooks/useUserProfile";
import { TenantApi } from "../../../../../api/TenantApi";
import { TableColumn } from "../../../../../components/Table";
import {
  ArrowCounterclockwise24Regular,
  EditRegular,
} from "@fluentui/react-icons";
import { useAdminContext } from "../context/AdminContext";
import TableSkeleton from "../../../../../components/Skeletons/TableSkeleton";
import { Alert } from "../../../../../components/Alert";
import { QueryKey } from "../../../../../constants/api";
import Pagination, {
  PaginationConfig,
} from "../../../../../components/Pagination";
import { DEFAULT_PAGE_SIZE } from "../../../../../constants/global";
import { USERS_ROLES } from "../constants";

export const USERS_COLUMNS: TableColumn[] = [
  { columnKey: "name", label: "Name" },
  { columnKey: "role", label: "Role", width: "100px" },
  { columnKey: "actions", label: "Actions", width: "70px" },
];

const useStyles = makeStyles({
  root: {
    ...shorthands.padding(tokens.spacingVerticalL, 0),
  },
  table: {
    display: "table !important",
  },
  tableHeader: {
    fontWeight: "bold",
  },
});

export const UsersTable = () => {
  const styles = useStyles();
  const [rows, setRows] = useState<User[]>([]);
  const { setIsEditUserDialogOpen, setEditUser } = useAdminContext();

  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    page: 0,
    limit: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const { teamsUser } = useUserProfile();

  const queryParams = {
    pagination: {
      page: selectedPage + 1,
      limit: pagination.limit,
    },
  };

  const {
    isLoading,
    isError,
    isSuccess,
    data: response,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKey.GetUsers,
      { tenantId: teamsUser?.tenantId, ...queryParams },
    ],
    queryFn: () => TenantApi.getUsers(teamsUser?.tenantId, queryParams),
    enabled: !!teamsUser?.tenantId,
  });

  useEffect(() => {
    if (response) {
      setRows(response.data);
      setPagination(response.pagination);
    }
  }, [response]);

  const onPageChange = (index: number) => {
    setSelectedPage(index);
  };

  const handleEditUser = (user: User) => {
    setIsEditUserDialogOpen(true);
    setEditUser(user);
  };

  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
  const focusableGroupAttr = useFocusableGroup({
    tabBehavior: "limited-trap-focus",
  });

  return (
    <div className={styles.root}>
      {/* <Title3>Users</Title3> */}

      {isLoading && <TableSkeleton />}

      {isError && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  data-testid="retry-fetch-users-button"
                  onClick={() => refetch()}
                />
              }
            />
          }
        />
      )}

      {isSuccess && rows.length === 0 && (
        <Alert
          intent="info"
          title="Well, well, well..."
          message={`For some odd reason, there are no users in your tenant. Maybe you should add some?`}
        />
      )}

      {isSuccess && rows.length > 0 && (
        <Table
          {...keyboardNavAttr}
          aria-label="Users table"
          size="small"
          className={styles.table}
          role="grid"
          data-testid="users-table"
        >
          <TableHeader>
            <TableRow>
              {USERS_COLUMNS.map((column) => (
                <TableHeaderCell
                  key={column.columnKey}
                  className={styles.tableHeader}
                  style={{ width: column.width ?? "auto" }}
                >
                  {column.label}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} data-testid="users-table-row">
                <TableCell tabIndex={0} role="gridcell">
                  <TableCellLayout>{row.name}</TableCellLayout>
                </TableCell>

                <TableCell tabIndex={0} role="gridcell">
                  <TableCellLayout>{USERS_ROLES[row.role]}</TableCellLayout>
                </TableCell>

                <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                  <TableCellLayout>
                    <Button
                      icon={<EditRegular />}
                      size="small"
                      appearance="transparent"
                      aria-label="Edit"
                      onClick={() => handleEditUser(row)}
                    />
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {(pagination?.total && pagination.total > pagination.limit) === true && (
        <Pagination
          selectedPage={selectedPage}
          pagination={pagination}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};
