import { DateTime } from "luxon";
import { ConfigurationDateFormat, ConfigurationTimeFormat } from "../constants";

export const previewDateFormat = (format: string): string => {
  let label: string = format;
  let example: string = DateTime.now().toFormat(format);

  if (format === ConfigurationDateFormat.Default) {
    label = "Default, your locale";
    example = DateTime.now().toLocaleString(DateTime.DATE_SHORT);
  }

  return `${example} (${label})`;
};

export const previewTimeFormat = (format: string): string => {
  let label: string = format;
  let example: string = DateTime.now().toFormat(format);

  if (format === ConfigurationTimeFormat.Default) {
    label = "Default, your locale";
    example = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
  }

  return `${example} (${label})`;
};
