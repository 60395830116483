import React, { useEffect, useState } from "react";
import {
  Button,
  MessageBarActions,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { Changelog, ChangelogApi } from "../../../../../api/ChangelogApi";
import { VersionCard } from "./VersionCard";
import AchievementSkeleton from "../../../../../components/Skeletons/AchievementSkeleton";
import { Alert } from "../../../../../components/Alert";
import { QueryKey } from "../../../../../constants/api";

interface VersionCardsProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },
});

export const VersionCards: React.FC<VersionCardsProps> = () => {
  const styles = useStyles();
  const [versions, setVersions] = useState<Changelog[]>([]);

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: [QueryKey.GetChangeLog],
    queryFn: () => ChangelogApi.getReleases(),
  });

  useEffect(() => {
    if (data) {
      setVersions(data);
    }
  }, [data]);

  return (
    <div className={styles.root}>
      {isLoading && (
        <div data-testid="version-cards--loading">
          <AchievementSkeleton />
          <AchievementSkeleton />
          <AchievementSkeleton />
        </div>
      )}

      {isError && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  onClick={() => refetch()}
                />
              }
            />
          }
        />
      )}

      {isSuccess && versions.length === 0 && (
        <Alert
          intent="info"
          title="Oh no!"
          message="There is no information for any releases yet."
        />
      )}

      {isSuccess &&
        versions.length > 0 &&
        versions.map((version) => (
          <VersionCard key={version.id} changelog={version} />
        ))}
    </div>
  );
};
