import {
  Select,
  makeStyles,
  shorthands,
  tokens,
  useId,
} from "@fluentui/react-components";
import { containerBreakpoints } from "../../../../utils/breakpoints";
import { endOfToday, startOfMonth, startOfToday, startOfWeek } from "date-fns";
import { useDashboardContext } from "../../context/DashboardContext";
import { useState } from "react";

interface ActivityFiltersProps {}

export enum ActivityPeriod {
  Today = "today",
  Week = "week",
  Month = "month",
  All = "all",
}

export const ActivityPeriodLabels: Record<ActivityPeriod, string> = {
  [ActivityPeriod.Today]: "Today",
  [ActivityPeriod.Week]: "Week",
  [ActivityPeriod.Month]: "Month",
  [ActivityPeriod.All]: "All",
};

export const ActivityPeriodRanges: Record<ActivityPeriod, [Date, Date]> = {
  [ActivityPeriod.Today]: [startOfToday(), endOfToday()],
  [ActivityPeriod.Week]: [
    startOfWeek(new Date(), { weekStartsOn: 1 }), // TODO: Add config for this
    endOfToday(),
  ],
  [ActivityPeriod.Month]: [startOfMonth(new Date()), endOfToday()],
  [ActivityPeriod.All]: [new Date("2023-12-13"), endOfToday()],
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalM),
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "space-between",
    }),
  },
});

export const ActivityFilters: React.FC<ActivityFiltersProps> = () => {
  const styles = useStyles();
  const filterByPeriodSelectId = useId("filterByPeriodSelect");
  const [selectedPeriod, setSelectedPeriod] = useState<ActivityPeriod>(
    ActivityPeriod.Today
  );

  const { setActivityFilterPeriod } = useDashboardContext();

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as ActivityPeriod;
    const range = ActivityPeriodRanges[value];

    setSelectedPeriod(value);
    setActivityFilterPeriod(range);
  };

  return (
    <div className={styles.root} data-testid="dashboard-activity-filters">
      <label htmlFor={filterByPeriodSelectId}>Filter by period</label>
      <Select
        id={filterByPeriodSelectId}
        value={selectedPeriod}
        onChange={handlePeriodChange}
      >
        <option value={ActivityPeriod.Today}>
          {ActivityPeriodLabels[ActivityPeriod.Today]}
        </option>
        <option value={ActivityPeriod.Week}>
          {ActivityPeriodLabels[ActivityPeriod.Week]}
        </option>
        <option value={ActivityPeriod.Month}>
          {ActivityPeriodLabels[ActivityPeriod.Month]}
        </option>
        <option value={ActivityPeriod.All}>
          {ActivityPeriodLabels[ActivityPeriod.All]}
        </option>
      </Select>
    </div>
  );
};

export default ActivityFilters;
