import { SetupChatForm } from "../tabs/Setup/components/ChatForm";
import { Api } from "./Api";
import { Team } from "./TeamApi";
import { Tenant } from "./TenantApi";

export type Chat = {
  id: number;
  tenantId: string;
  teamId: string | null;
  providerChatId: string;
  tenant: Tenant | null;
  team: Team | null;
};

export class ChatApi {
  public static API_ENDPOINT = `chats`;

  public static async getChat(providerChatId: string | null): Promise<Chat> {
    const url = `${ChatApi.API_ENDPOINT}/${providerChatId}`;
    const response = await Api.get(url);

    return response;
  }

  public static async setupChat(data: SetupChatForm): Promise<Chat> {
    const url = `${ChatApi.API_ENDPOINT}/`;
    const response = await Api.post(url, data);

    return response;
  }
}
