import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import ReactGA from "react-ga4";

import App from "./App";
import "./index.css";
import {
  APP_INSIGHTS_ENVIRONMENTS,
  GOOGLE_ANALYTICS_ENVIRONMENTS,
} from "./constants/global";
import config from "./constants/config";

// Create an API client
const queryClient = new QueryClient();

const { env, appInsightsConnectionString, googleAnalyticsMeasurementId } =
  config;

if (
  env &&
  APP_INSIGHTS_ENVIRONMENTS.includes(env) &&
  appInsightsConnectionString
) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appInsightsConnectionString,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });

  console.log("Initializing AppInsights");
  appInsights.loadAppInsights();
}

if (
  env &&
  GOOGLE_ANALYTICS_ENVIRONMENTS.includes(env) &&
  googleAnalyticsMeasurementId
) {
  console.log(
    "Initializing Google Analytics: %s",
    googleAnalyticsMeasurementId
  );
  ReactGA.initialize(googleAnalyticsMeasurementId);
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
