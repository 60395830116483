import React from "react";
import {
  Divider,
  LargeTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { containerBreakpoints } from "../../utils/breakpoints";
import Setup from "../Setup";
import { useStoreContext } from "../../context/StoreContext";
import { UserAchievements } from "./components/UserAchievements";
import { AchievementsProvider } from "./context/AchievementsContext";
import GlobalFilters from "./components/GlobalFilters";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    containerType: "inline-size",
    containerName: "page-container",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
    }),
  },
  headerTitle: {
    ...containerBreakpoints.xs({
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
    }),
  },
});

export const Achievements = () => {
  const styles = useStyles();

  const { isTenantConfigured } = useStoreContext();

  if (!isTenantConfigured) {
    return <Setup />;
  }

  return (
    <AchievementsProvider>
      <div className={styles.root}>
        <div className={styles.header}>
          <LargeTitle block className={styles.headerTitle}>
            Achievements
          </LargeTitle>
          <GlobalFilters />
        </div>
        <Divider />
        <UserAchievements />
      </div>
    </AchievementsProvider>
  );
};
