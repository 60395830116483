import { tokens } from "@fluentui/react-components";
import { Pagination as FluentUiPagination } from "@fluentui/react-experiments";
import { containerBreakpoints } from "../../utils/breakpoints";

export interface PaginationConfig {
  page: number;
  limit: number;
  total?: number;
}

export interface PaginationProps {
  selectedPage: number;
  pagination: PaginationConfig;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  selectedPage,
  pagination,
  onPageChange,
}) => {
  const pageCount = Math.ceil(pagination?.total! / pagination.limit);

  return (
    <FluentUiPagination
      selectedPageIndex={selectedPage}
      pageCount={pageCount}
      itemsPerPage={pagination.limit}
      totalItemCount={pagination.total}
      format={"buttons"}
      previousPageAriaLabel={"Previous page"}
      nextPageAriaLabel={"Next page"}
      firstPageAriaLabel={"Go to First page"}
      lastPageAriaLabel={"Go to Last page"}
      pageAriaLabel={"page"}
      selectedAriaLabel={"selected"}
      onPageChange={onPageChange}
      numberOfPageButton={3}
      styles={{
        root: {
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `${tokens.spacingHorizontalM} ${tokens.spacingHorizontalM}`,

          "& > div[role='radiogroup']": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          },

          ...containerBreakpoints.xs({
            flexDirection: "column",
          }),
        },
        pageNumber: {},
        previousNextPage: {},
        previousNextPageDisabled: {},
        visibleItemLabel: {},
      }}
    />
  );
};

export default Pagination;
