import React from "react";
import {
  Body1,
  Caption1,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import { useUserProfile } from "../../../DashBoard/hooks/useUserProfile";
import UserFeedbackForm from "./components/UserFeedbackForm";
import { useStoreContext } from "../../../../context/StoreContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Feedback = () => {
  const styles = useStyles();

  const { user } = useUserProfile();
  const { dispatchToast } = useStoreContext();

  const onUserFeedbackSubmittedSuccessfully = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>Feedback submitted successfully!</ToastTitle>
        <ToastBody subtitle="Thank you!">
          I really appreciate your comments and I'm happy to see your thoughts.
        </ToastBody>
      </Toast>,
      { intent: "success" }
    );
  };

  return (
    <TabPanel title="Send Feedback">
      <div className={styles.root}>
        <Body1>
          At PizzaTime, your thoughts matter as much as your favorite pizza
          toppings! Your feedback is the secret sauce that helps us enhance the
          experience for everybody.
        </Body1>
        <br />
        <br />
        <Caption1>
          Whether you have ideas to sprinkle, concerns to knead out, or simply
          want to express your appreciation, your feedback shapes the future of
          PizzaTime. Together, let's make this app even cheesier (in the best
          way possible)! Drop us a line, and let the feedback fest begin!
        </Caption1>
        <br />
        <br />

        <UserFeedbackForm
          user={user}
          onSuccess={onUserFeedbackSubmittedSuccessfully}
        />
      </div>
    </TabPanel>
  );
};
