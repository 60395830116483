import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import { VersionCards } from "./components/VersionCards";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Changelog = () => {
  const styles = useStyles();

  return (
    <TabPanel title="Changelog">
      <div className={styles.root}>
        <VersionCards />
      </div>
    </TabPanel>
  );
};
