import { FC, useState } from "react";
import { makeStyles } from "@fluentui/react-components";

import { Alert } from "../../../components/Alert";
import ChatForm from "./ChatForm";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
});

interface SetupTabProps {
  onSuccess: () => void;
}

const SetupTab: FC<SetupTabProps> = ({ onSuccess }) => {
  const styles = useStyles();

  const [isTabConfigured, setIsTabConfigured] = useState<boolean>(false); // Note: think about moving it outside

  const onTabSetupSuccess = () => {
    // Trigger some action after the team is created
    // Note: if added to a chat, teams , etc. we should update the Set up button
    setIsTabConfigured(true);
    onSuccess();
  };

  if (isTabConfigured) {
    return (
      <div className={styles.root}>
        <Alert
          intent="success"
          title="All set!"
          message="Your tab is now configured and ready to use. "
        />
        <p>
          Send virtual pizza slices, earn badges, level up, and spice up your
          team appreciation game! Let's make teamwork tastier and celebrations
          cheesier together!
        </p>
        <p>PS: Click the 'Save' button below to proceed.</p>
      </div>
    );
  }

  return (
    <div>
      <h2>Assign to Team</h2>
      <p>
        Depending on the size of your company, you may want to split the
        leaderboard into different teams and measure the best endorsers
        separately.
      </p>

      <ChatForm onSuccess={onTabSetupSuccess} />
    </div>
  );
};

export default SetupTab;
