export enum ConfigurationDateFormat {
  Default = "locale",
  Example1 = "yyyy-MM-dd",
  Example2 = "dd/MM/yyyy",
  Example3 = "dd.MM.yyyy",
  Example4 = "MM/dd/yyyy",
  Example5 = "DD",
  Example6 = "DDD",
  Example7 = "DDDD",
}

export enum ConfigurationTimeFormat {
  Default = "locale",
  Example1 = "hh:mm a",
  Example2 = "h:m a",
  Example3 = "HH:mm",
  Example4 = "H:m",
}
