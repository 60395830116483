import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { LeaderBoardTableRow } from "../components/LeaderBoardTable";

const LeaderBoardContext = createContext<{
  teamId: number;
  setTeamId: (value: number) => void;

  isUserProfileDialogOpen: boolean;
  setIsUserProfileDialogOpen: (value: boolean) => void;

  selectedUser: LeaderBoardTableRow | null | undefined;
  setSelectedUser: (value: LeaderBoardTableRow | null | undefined) => void;
}>({
  teamId: 0,
  setTeamId: () => {},

  isUserProfileDialogOpen: false,
  setIsUserProfileDialogOpen: () => {},

  selectedUser: null,
  setSelectedUser: () => {},
});

interface LaderBoardContextProviderProps extends PropsWithChildren<{}> {}

export const LeaderBoardProvider: FC<LaderBoardContextProviderProps> = ({
  children,
}) => {
  const [teamId, setTeamId] = useState<number>(0);
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] =
    useState<LeaderBoardTableRow | null>();

  return (
    <LeaderBoardContext.Provider
      value={{
        teamId,
        setTeamId,

        isUserProfileDialogOpen,
        setIsUserProfileDialogOpen,

        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </LeaderBoardContext.Provider>
  );
};

export const useLeaderBoardContext = () => {
  const context = useContext(LeaderBoardContext);

  if (!context) {
    throw new Error(
      "useLeaderBoardContext must be used within a LeaderBoardProvider"
    );
  }

  return context;
};
