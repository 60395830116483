export enum QueryKey {
  GetTenant = "getTenant",
  CreateTenant = "createTenant",
  GetTeams = "getTeams",
  GetChat = "getChat",
  SetupChat = "setupChat",
  GetUsers = "getUsers",
  GetUser = "getUser",
  GetUserProfile = "getUserProfile",
  GetUserActivity = "getUserActivity",
  GetUserAchievements = "getUserAchievements",
  UpdateUser = "updateUser",
  UpdateUserFeedback = "updateUserFeedback",
  UpdateUserConfiguration = "updateUserConfiguration",
  SendUserFeedback = "sendUserFeedback",
  GetLeaderBoardEndorsers = "getLeaderBoardEndorsers",
  GetLeaderBoardRecipients = "getLeaderBoardRecipients",
  GetChangeLog = "getChangeLog",
  EndorseChangelog = "endorseChangelog",
}
