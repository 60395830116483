import * as React from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  makeStyles,
  tokens,
  shorthands,
  Subtitle2,
  Body1,
  Card,
  CardPreview,
  Subtitle1,
  Tooltip,
  Button,
} from "@fluentui/react-components";
import { useLeaderBoardContext } from "../context/LeaderBoardContext";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../../constants/api";
import { UserApi } from "../../../api/UserApi";
import { LineSkeleton } from "../../../components/Skeletons/LineSkeleton";
import {
  USER_LEVEL_AVATARS,
  USER_LEVEL_LABELS,
} from "../../../constants/levels";
import { containerBreakpoints } from "../../../utils/breakpoints";
import { Dismiss24Regular, ErrorCircle20Regular } from "@fluentui/react-icons";
import { AchievementCard } from "../../Achievements/components/AchievementCard";

const useStyles = makeStyles({
  wrapper: {
    // maxWidth: "350px",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    rowGap: "10px",
  },
  card: {
    display: "flex",
    width: "300px",
    height: "auto",
    ...shorthands.margin("auto"),
    ...containerBreakpoints.xs({
      width: "100%",
    }),
  },
  cardPreview: {
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
  },
  userAvatar: {
    aspectRatio: "1 / 1",
    height: "auto !important",
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.margin(0, "auto", tokens.spacingVerticalM, "auto"),
  },
  userInfo: {
    textAlign: "center",
  },
  userErrorTooltip: {
    color: tokens.colorPaletteRedBackground3,
    position: "relative",
    top: "3px",
    left: "4px",
    cursor: "pointer",
  },
  profileInfo: {
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
  },
  scorePoints: {
    display: "flex",
    flexDirection: "row",

    ...shorthands.gap(tokens.spacingVerticalS),
  },
  achievements: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    ...shorthands.margin(tokens.spacingVerticalM, 0),
    ...shorthands.gap(tokens.spacingVerticalM),
  },
});

interface UserProfileDialogProps {}

export const UserProfileDialog: React.FC<UserProfileDialogProps> = () => {
  const styles = useStyles();
  const {
    isUserProfileDialogOpen,
    setIsUserProfileDialogOpen,
    selectedUser,
    setSelectedUser,
  } = useLeaderBoardContext();

  const {
    isLoading: isLoadingUser,
    isError: isErrorUser,
    isSuccess: isSuccessUser,
    data: userProfile,
    refetch: refetchUser,
  } = useQuery({
    queryKey: [QueryKey.GetUserProfile, selectedUser?.providerUserId],
    queryFn: () => UserApi.getUserProfile(selectedUser?.providerUserId),
    enabled: !!selectedUser?.providerUserId,
  });

  // Aliases for easier usage later
  const userAvatar = USER_LEVEL_AVATARS[userProfile?.level ?? -1];
  const userName = userProfile?.name;
  const userLevel = userProfile?.level ?? 0;
  const userLevelLabel = USER_LEVEL_LABELS[userLevel];
  const userScore = userProfile?.score ?? 0;
  const userGoldAchievements = userProfile?.badges.gold ?? 0;
  const userSilverAchievements = userProfile?.badges.silver ?? 0;
  const userBronzeAchievements = userProfile?.badges.bronze ?? 0;
  const userOtherAchievements = userProfile?.badges.other ?? 0;

  const handleCloseClick = () => {
    setIsUserProfileDialogOpen(false);
    setSelectedUser(null);
  };

  return (
    <Dialog open={isUserProfileDialogOpen}>
      <DialogSurface aria-describedby={undefined} className={styles.wrapper}>
        <DialogBody data-testid="user-profile-dialog">
          <DialogTitle
            action={
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={() => handleCloseClick()}
              />
            }
            className={styles.dialogTitle}
          >
            User Profile
          </DialogTitle>
          <DialogContent className={styles.content}>
            <Card className={styles.card}>
              <CardPreview className={styles.cardPreview}>
                <img
                  src={userAvatar}
                  alt={userName}
                  className={styles.userAvatar}
                />

                {isLoadingUser && (
                  <div data-testid="user-profile-skeleton-loader">
                    <LineSkeleton
                      style={{ marginTop: "4px", width: "150px" }}
                    />
                    <LineSkeleton
                      style={{ marginTop: "4px", width: "100px" }}
                    />
                  </div>
                )}

                {isSuccessUser && (
                  <div
                    className={styles.profileInfo}
                    data-testid="user-profile-content"
                  >
                    <Subtitle1 block className={styles.userInfo}>
                      {userName}
                    </Subtitle1>

                    <Subtitle2 block>
                      {userLevelLabel} (Level {userLevel})
                    </Subtitle2>

                    <Body1 block className={styles.scorePoints}>
                      <span>🍕{userScore}</span>
                      <span>🥇{userGoldAchievements}</span>
                      <span>🥈{userSilverAchievements}</span>
                      <span>🥉{userBronzeAchievements}</span>
                      <span>🎖️{userOtherAchievements}</span>
                    </Body1>

                    <div className={styles.achievements}>
                      <Subtitle2>Achievements</Subtitle2>
                      {userProfile?.achievements.map((achievement) => (
                        <AchievementCard
                          key={achievement.id}
                          userAchievement={achievement}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {isErrorUser && (
                  <div className={styles.profileInfo}>
                    <Subtitle1 block className={styles.userInfo}>
                      Oppsie!
                      <Tooltip
                        withArrow
                        content="Unable to load user profile data."
                        relationship="label"
                      >
                        <ErrorCircle20Regular
                          className={styles.userErrorTooltip}
                          onClick={() => refetchUser()}
                          data-testid="retry-fetch-user-profile-button"
                        />
                      </Tooltip>
                    </Subtitle1>
                  </div>
                )}
              </CardPreview>
            </Card>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
