import React from "react";
import {
  Card,
  CardHeader,
  CardPreview,
  Divider,
  Title1,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ActivityTable } from "./Activity/ActivityTable";
import ActivityFilters from "./Activity/ActivityFilters";
import { containerBreakpoints } from "../../../utils/breakpoints";

interface ActivityProps {}

const useStyles = makeStyles({
  root: {},
  cardHeader: {
    display: "flex",
    "& > div": {
      display: "flex",
      flexGrow: 1,
    },
  },
  cardHeaderContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    ...containerBreakpoints.xs({
      flexWrap: "wrap",
      flexDirection: "column",
      ...shorthands.gap(tokens.spacingHorizontalM),
    }),
  },
  cardPreview: {
    ...shorthands.padding(tokens.spacingHorizontalS),
  },
});

export const Activity: React.FC<ActivityProps> = () => {
  const styles = useStyles();

  return (
    <Card className={styles.root}>
      <CardHeader
        className={styles.cardHeader}
        header={
          <div className={styles.cardHeaderContent}>
            <Title1>Activity</Title1>
            <ActivityFilters />
          </div>
        }
      ></CardHeader>
      <Divider />

      <CardPreview className={styles.cardPreview}>
        <ActivityTable />
      </CardPreview>
    </Card>
  );
};
