import React, { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import Setup from "../tabs/Setup";

/**
 * This component is used to display the required
 * tab configuration
 */
const Config = () => {
  useEffect(() => {
    const initializeTeams = async () => {
      try {
        await microsoftTeams.app.initialize();
        await microsoftTeams.app.notifySuccess();

        microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
          const url = `${window.location.origin}/index.html#tab`;
          const entityId = `PizzaTime`;

          microsoftTeams.pages.config.setConfig({
            entityId: entityId,
            contentUrl: url,
            websiteUrl: url,
          });

          saveEvent.notifySuccess();
        });
      } catch (error) {
        console.error(error);
      }
    };

    initializeTeams();
  }, []);

  const handleTenantCreated = () => {
    microsoftTeams.pages.config.setValidityState(true);
  };

  return (
    <div>
      <Setup onSuccess={handleTenantCreated} />
    </div>
  );
};

export default Config;
