import React, { useContext } from "react";
import {
  Button,
  MessageBarActions,
  Toast,
  ToastTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { TabPanel } from "../TabPanel";
import { TeamsFxContext } from "../../../../context/TeamsFxContext";
import {
  getTeamsContext,
  getTeamsContextChatId,
} from "../../../../utils/helpers";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatApi } from "../../../../api/ChatApi";
import AchievementSkeleton from "../../../../components/Skeletons/AchievementSkeleton";
import { Alert } from "../../../../components/Alert";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { CHAT_TYPE_LABELS } from "./constants";
import ChatForm from "../../../Setup/components/ChatForm";
import { useStoreContext } from "../../../../context/StoreContext";
import { QueryKey } from "../../../../constants/api";

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalL),
  },
});

export const Chat = () => {
  const styles = useStyles();
  const { teams } = useContext(TeamsFxContext);
  const { dispatchToast } = useStoreContext();

  const queryClient = useQueryClient();
  const providerChatId = getTeamsContextChatId(teams);

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: [QueryKey.GetChat, providerChatId],
    queryFn: () => ChatApi.getChat(providerChatId),
    enabled: !!providerChatId,
  });

  const onChatSettingsUpdatedSuccessfully = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GetChat, providerChatId],
    });

    dispatchToast(
      <Toast>
        <ToastTitle>
          {CHAT_TYPE_LABELS[getTeamsContext(teams)]} Configurations updated
          successfully!
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );
  };

  return (
    <TabPanel
      title={`${CHAT_TYPE_LABELS[getTeamsContext(teams)]} Configurations`}
    >
      <div className={styles.root}>
        {isLoading && (
          <>
            <AchievementSkeleton />
          </>
        )}

        {isError && (
          <Alert
            intent="error"
            actions={
              <MessageBarActions
                containerAction={
                  <Button
                    appearance="transparent"
                    icon={<ArrowCounterclockwise24Regular />}
                    onClick={() => refetch()}
                  />
                }
              />
            }
          />
        )}

        {isSuccess && data && (
          <ChatForm chat={data} onSuccess={onChatSettingsUpdatedSuccessfully} />
        )}
      </div>
    </TabPanel>
  );
};
